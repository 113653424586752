@import "../../common/colors.scss";
.contentpart {
    padding-top: 86px;
    min-height: 100%;
    padding-left: 250px;
    background: $lightgray;
    height: 100%;
    .right_content_col {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
        .pagename {
            background: $white;
            padding: 10px 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
            letter-spacing: 0.01em;
            color: #343534;
            border-bottom: 1px solid #eaeaea;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ant-breadcrumb {
                font-size: 16px;
            }
        }
        .content_wraper {
            padding: 32px;
            overflow: auto;
            &::-webkit-scrollbar {
                width: 1px;
                height: 1px;
            }
            &::-webkit-scrollbar-thumb {
                background: hsla(0, 0%, 100%, 0.2);
                border-radius: 3px;
                -webkit-box-shadow: inset 0 0 5px hsla(0, 0%, 100%, 0.05);
            }
            &::-webkit-scrollbar-track {
                background: hsla(0, 0%, 100%, 0.15);
                border-radius: 3px;
                -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
            }
        }
    }
}
.dashboardPage {
    .card_wrap {
        margin-bottom: 32px;
    }
    .ant-spin-nested-loading {
        position: relative;
        z-index: 9;
        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
            color: $darkgreen;
        }
    }
    .ant-spin-dot-item {
        background-color: $darkgreen;
    }
    .ant-card {
        border: 1px solid #ebebeb;
        min-height: 100%;
        .ant-row {
            flex-flow: row nowrap;
            padding-right: 40px;
        }
        .ant-card-body {
            padding: 15px;
            .ant-progress-line {
                margin-top: 24px;
                position: static;
                height: 5px;
                vertical-align: top;
                .ant-progress-text {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #7e7e7e;
                }
            }
            .ant-progress-show-info .ant-progress-outer {
                margin-right: 0;
                padding-right: 0;
            }
            .ant-progress-inner {
                border-radius: 0;
                vertical-align: top;
            }
            .ant-progress-success-bg,
            .ant-progress-bg {
                border-radius: 0;
                background: $lightgreen;
                height: 5px !important;
            }
            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 24px;
                color: $black;
                margin: 0;
                margin-top: 4px;
            }
            h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #7e7e7e;
                margin: 0;
            }
            .cardicon {
                margin: 0px 15px;
            }
        }
    }
    .dashboard-welcome-box {
        background: #a1c9a4;
        background-size: cover;
        margin: 0 !important;
        padding: 32px;
        margin-bottom: 32px !important;
        .left_content {
            padding-top: 16px;
            padding-bottom: 16px;
            h3 {
                font-style: normal;
                font-weight: 800;
                font-size: 48px;
                line-height: 50px;
                color: $white;
                text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                margin: 0;
            }
            .linelogo {
                max-width: 305px;
                margin-bottom: 24px;
                width: 100%;
            }
            padding-right: 32px;
        }
    }
    .productList {
        border: 1px solid #ebebeb;
        background: $white;
        &.mystatsgrid {
            margin-bottom: 32px;
            .statshead {
                display: inline-flex;
                .searchhproduct {
                    margin-right: 8px;
                }
                .monthwrap {
                    margin-right: 8px;
                }
            }
        }
        .noProduct {
            padding: 24px 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: $black;
            h3 {
                margin: 0;
                padding: 0;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                letter-spacing: 0.01em;
            }
            .monthwrap {
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    height: 36px;
                    padding: 7px 15px;
                }
            }

            .searchhproduct {
                min-height: 36px;
                max-height: 36px;
                display: inline-block;
                vertical-align: top;
                .ant-input-affix-wrapper {
                    border: 1px solid #e2e2e2;
                    min-height: inherit;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.01em;
                    box-shadow: none;
                    min-width: 250px;
                    vertical-align: top;
                    border-radius: 0;
                    padding: 5px 0;
                    padding-left: 10px;
                    .ant-input-suffix {
                        font-size: 20px;
                    }
                }
            }
            .ant-btn.ant-btn-sm {
                padding: 0px 10px;
            }
            .ant-btn.ant-btn-sm:disabled {
                background-color: #f5f5ff;
            }
        }
    }
    .table_grid {
        .action {
            color: $darkgray;
            font-size: 20px;
        }
        .ant-space {
            a {
                .anticon {
                    color: $darkgray;
                    font-size: 20px;
                }
            }
        }
        .ant-table-thead > tr > th {
            background: #fff;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #8e8e8e;
            text-transform: capitalize;
            &:first-child {
                .ant-checkbox {
                    margin-left: 10px;
                }
            }
        }
        .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #343534;
            .brandimg {
                max-width: 80px;
                border: 1px solid #f1f1f1;
                padding: 3px;
                background: #fff;
                img {
                    max-width: 100%;
                }
            }
            &:first-child {
                .ant-checkbox {
                    margin-left: 10px;
                }
            }
        }
    }
    .ant-table-pagination.ant-pagination {
        margin: 16px 32px;
    }
    .ant-pagination-item-active {
        border: 0;
    }
    .ant-pagination-item a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #8e8e8e;
    }
    .ant-pagination-item-active a {
        color: $white;
        background: $darkgreen;
        border: 1px solid #ebebeb;
    }
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next {
        min-width: 20px;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
        font-size: 16px;
        border: 0;
        margin: 0;
        padding: 0;
    }
    .chart-wrapper {
        padding: 16px;
        background-color: #fff;
        border: 1px solid #ebebeb;
    }
}
@media only screen and (max-width: 1180px) {
    .dashboardPage .productList .noProduct .searchhproduct .ant-input-affix-wrapper {
        min-width: 200px;
        max-width: 96px;
    }
    .dashboardPage .productList .noProduct {
        padding: 16px 16px;
    }
}
@media only screen and (max-width: 991px) {
    .contentpart {
        .right_content_col {
            .pagename {
                padding: 16px;
            }
            .content_wraper {
                padding: 16px;
            }
        }
        padding-left: 0;
    }
    .dashboardPage {
        .card_wrap {
            margin-bottom: 16px;
        }
        .dashboard-welcome-box {
            margin-bottom: 16px !important;
            padding: 16px;
            .left_content {
                h3 {
                    font-size: 32px;
                    line-height: 38px;
                }
            }
            .pluseDivider {
                margin-top: 66px;
            }
            .content_overlay {
                padding: 16px 16px 0px 16px;
            }
        }
        .productList {
            .noProduct {
                padding: 16px;
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .contentpart .right_content_col .pagename {
        display: block;
        padding: 8px 16px;
    }
    .contentpart .right_content_col .pagename .ant-breadcrumb {
        font-size: 14px;
    }
    .dashboardPage {
        .dashboard-welcome-box {
        }
        .card_wrap {
            margin-bottom: 0;
        }
        .ant-card {
            min-height: inherit;
            margin-bottom: 16px;
        }
    }
    .dashboardPage .productList .noProduct .searchhproduct {
        display: none;
    }
}
@media only screen and (max-width: 400px) {
    .dashboardPage .productList.mystatsgrid .statshead {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
    .dashboardPage .productList.mystatsgrid .statshead .monthwrap {
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.infinite-scroll-component {
    overflow-x: hidden !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #a1c9a4;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: rgba(159,195,163,0.1);
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {   
    
        background-color: rgba(159,195,163,0.1);
    
}