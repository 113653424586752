div.message-box {
  height: 30em;
  position: relative;
}

div.message-box h1 {
  font-size: 30px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.pre-text {
  white-space: pre-wrap;
}

.productPage {
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: calc(100vh - 312px);
  flex-wrap: nowrap;

  .filter_left_mob {
    position: relative;
    display: none;

    .ant-drawer {
      top: 115px;
      max-height: calc(100vh - 115px);
    }

    .filterbtn {
      position: fixed;
      left: 0;
      top: 86px;
      width: 30px;
      height: 30px;
      padding: 0;
      line-height: normal;
      min-height: inherit;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      z-index: 9;

      .anticon {
        font-size: 16px;
      }
    }
  }

  .filter_left {
    background: #44514e;
    max-width: 210px;
    padding: 16px;
    color: #a1a8a6;
  }

  .category_one {
    padding-bottom: 10px;
    margin-bottom: 16px;
    border-bottom: 1px solid #a1a8a6;

    .ant-checkbox-group {
      .ant-checkbox-group-item.ant-checkbox-wrapper {
        min-height: 32px;
      }
    }

    .ant-radio-inner {
      position: relative;
      top: 0;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-color: #a1c9a4;
      border-style: solid;
      border-width: 1px;
      border-radius: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }

    .impact-filter-div {
      .ant-radio-wrapper {
        width: 100%;
        color: #a1a8a6;
        font-size: 14px;
        margin: 5px 0;
      }

      .ant-radio-inner::after {
        background-color: #fff;
        border-radius: 0;
      }
    }

    &:last-child {
      margin: 0;
      padding: 0;
      border: 0;
    }

    h3 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15px;
      color: #fff;
      margin: 0;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form label {
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #a1a8a6;
    }

    .ant-checkbox-inner {
      background-color: transparent;
    }

    .ant-btn {
      &.ant-btn-link {
        background: none;
        color: #a1a8a6;
        padding: 0;
        min-height: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 11px;
        text-decoration: underline;

        &:hover {
          color: #fff;
        }
      }

      &.ant-btn-loading:before {
        display: none;
      }
    }

    .ant-btn>span {
      display: inline-block;
      text-decoration: underline;
    }
  }

  .product_content {
    padding: 0px 0px 0px 16px;
    width: 100%;

    .infinite-scroll-component {
      overflow-x: hidden !important;
    }

    .blogbanner {
      padding: 16px;
      background: #ddebde;
      margin-bottom: 16px;

      .heading {
        padding-bottom: 16px;

        h5 {
          font-size: 16px;
          color: #000;
          margin: 0;
          font-weight: 700;
        }

        h3 {
          font-size: 22px;
          color: #000;
          margin: 0;
          font-weight: 700;
          line-height: 20px;
        }
      }

      .morecontent {
        flex-wrap: nowrap;
        justify-content: space-between;

        .left_cont {
          width: 100%;

          h4 {
            max-width: 580px;
            font-weight: normal;
            font-size: 16px;
            color: #000;
            margin: 0;
            border-bottom: 3px solid #000;
            position: relative;
            padding-bottom: 5px;

            &::before {
              content: "";
              height: 18px;
              width: 3px;
              background: #000;
              position: absolute;
              bottom: -10px;
              right: 8px;
            }

            &::after {
              width: 3px;
              height: 16px;
              content: "";
              background: #ddebde;
              position: absolute;
              right: 19px;
              bottom: -9px;
            }
          }

          .inr_cont {
            display: flex;
            padding-top: 16px;

            .imgD {
              img {
                max-width: 120px;
              }
            }

            .ant-carousel {
              max-width: 120px;
              margin-right: 16px;
              min-width: 120px;
            }

            .imgcont {
              font-size: 16px;
              font-weight: normal;
              color: #000;
              line-height: 20px;

              h3 {
                font-size: 18px;
                color: #000;
                margin: 0;
                font-weight: 700;
                margin-bottom: 5px;
              }
            }
          }
        }

        .right_cont {
          .biglink {
            background: none;
            color: #000;
            padding: 0;
            min-height: inherit;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 11px;
            text-transform: uppercase;
            margin-top: 40px;
          }

          .smalllink {
            background: none;
            color: #000;
            padding: 0;
            min-height: inherit;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            display: block;
            height: auto;
          }

          h5 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 11px;
            text-transform: uppercase;
            margin-top: 40px;
          }
        }
      }
    }

    .proList {
      background: #ffffff;
      border: 1px solid #ebebeb;
      padding: 16px 32px;

      .title {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        // text-transform: capitalize;
        padding: 0px 0 16px 0;

        &.second {
          display: flex;
          justify-content: space-between;

          .mark-icon {
            color: #343434;
            cursor: pointer;
            position: relative;

            .mark-description {
              position: absolute;
              top: 16px;
              margin-top: 25px;
              background: #fff;
              z-index: 9;
              padding: 32px;
              width: 100%;
              max-width: 1200px;
              right: 0;
              width: calc(100vw - 15vw);
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
              display: none;
            }

            &:hover {
              .mark-description {
                display: block;
              }
            }
          }
        }
      }

      .ant-col {
        padding-bottom: 32px;
      }

      .prowrap {
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        height: 100%;

        .imagdiv {
          position: relative;
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 310px;
          cursor: pointer;

          img {
            width: 100%;
          }
        }

        .Description {
          margin-bottom: 16px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          text-transform: capitalize;
          color: #6f6f6f;
          min-height: 68px;
          flex: auto;
          display: flex;
          flex-direction: column;

          p {
            margin: 0;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            min-height: 40px;
          }

          h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.01em;
            margin: 0;
            position: relative;
            padding: 0px 32px;
            padding-left: 0;
            margin-bottom: 5px;
            flex: auto;

            span {
              cursor: pointer;
            }

            .favrout {
              position: absolute;
              right: 0;
              top: -8px;
              background: #fff;
              width: 32px;
              height: 32px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #f1f1f1;
              font-size: 18px;

              .ant-checkbox-inner {
                display: none;
              }

              .anticon {
                color: #000;
              }

              .ant-checkbox+span {
                display: flex;
              }

              .fill {
                display: none;
              }

              .ant-checkbox.ant-checkbox-checked+span>.fill {
                display: inline-block;
              }

              .ant-checkbox.ant-checkbox-checked+span>.nonfill {
                display: none;
              }
            }
          }
        }

        ul {
          margin: 0;
          padding: 0;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          li {
            font-style: normal;
            font-size: 12px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.01em;
            color: rgba(52, 52, 52, 0.91);
            margin-bottom: 1px;
            height: 100%;
            min-width: 80px;

            h3 {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 26px;
              letter-spacing: 0.01em;
              text-transform: capitalize;
              color: #343434;
              margin: 0;
            }

            h4 {
              color: #41ac60;
              font-size: 13px;
              margin: 0;
            }

            .icondiv {
              img {
                max-width: 45px;
              }
            }

            .size-label {
              background: #a1c9a4;
              color: #fff;
              width: 33px;
              padding: 5px;
              text-align: center;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .price_compare_wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #ebebeb;
          padding-top: 10px;
        }

        .price {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          letter-spacing: 0.01em;
          text-align: center;
          cursor: pointer;
        }

        .ant-btn.ant-btn-primary {
          width: 100%;
          position: relative;
          padding: 0px 16px;
          margin-bottom: 16px;

          .anticon {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .compare {
          display: flex;

          label {
            font-size: 13px;
            display: inline-flex;
            align-items: center;
            color: #000;
          }
        }
      }

      .bottomrow {
        display: flex;
        justify-content: center;
        margin-top: -10px;
        margin-bottom: 10px;

        .ant-btn {
          padding: 0px 10px;

          &.comparebtn {
            position: fixed;
            right: 94px;
            bottom: 38px;
            background: #a1c9a4;
            z-index: 9;

            .compare-count {
              width: 20px;
              height: 20px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #fff;
              border-radius: 20%;
              margin-left: 8px;
              font-size: 13px;
              line-height: 15px;
            }
          }
        }

        .ant-pagination-item-active a {
          color: #fff;
          background: #44514e;
        }

        .ant-pagination-item-active {
          border-color: #44514e;
        }

        .ant-pagination-item:focus,
        .ant-pagination-item:hover {
          border-color: #44514e;
        }

        .ant-pagination-item:focus a,
        .ant-pagination-item:hover a {
          color: #fff;
          background: #44514e;
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          font-size: 14px;
        }

        .ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-jump-prev,
        .ant-pagination-jump-next {
          min-width: 20px;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 16px;
  }
}

.compare-product {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 16px;
  margin: 16px 0px 16px 0px;
  overflow-x: auto;

  >.ant-row {
    min-width: 947px;
  }

  .prowrap {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .comp_wrp {
      flex: auto;
      height: 100%;
    }

    .comp_size_wrp {
      height: 100%;
    }

    .comp_desp {
      flex: auto;
    }

    .icon_txt {
      line-height: 16px;
      font-weight: bold;
    }

    .noimage {
      position: relative;

      img {
        width: 100%;
      }

      .ant-btn {
        background: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #e7e7e7;

        .anticon {
          font-size: 80px;
          color: #d9d9d9;
        }
      }
    }

    .imagdiv {
      position: relative;
      margin-bottom: 16px;
      // flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 340px;
      max-height: 340px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
      }
    }

    a.closecompare {
      background: #343434;
      width: 24px;
      height: 24px;
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
    }

    .Description {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: #6f6f6f;
      min-height: 36px;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        margin: 0;
        padding: 0px 32px;
        position: relative;

        span {
          cursor: pointer;
        }

        .favrout {
          position: absolute;
          right: 0;
          top: -8px;
          background: #fff;
          width: 32px;
          height: 32px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #f1f1f1;
          font-size: 18px;

          .ant-checkbox-inner {
            display: none;
          }

          .anticon {
            color: #000;
          }

          .ant-checkbox+span {
            display: flex;
          }

          .fill {
            display: none;
          }

          .ant-checkbox.ant-checkbox-checked+span>.fill {
            display: inline-block;
          }

          .ant-checkbox.ant-checkbox-checked+span>.nonfill {
            display: none;
          }
        }
      }
    }

    ul.iconlist {
      margin: 0;
      padding: 0;
      padding-left: 30px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-around;
      min-height: 78px;

      li {
        font-style: normal;
        font-size: 12px;
        line-height: 26px;
        text-align: center;
        letter-spacing: 0.01em;
        color: rgba(52, 52, 52, 0.91);
        margin-bottom: 1px;
        max-width: 33%;

        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.01em;
          text-transform: capitalize;
          color: #343434;
          margin: 0;
          min-height: 32px;
        }

        .icondiv {
          img {
            max-width: 45px;
          }
        }
      }
    }

    ul.iconvalue {
      display: flex;
      justify-content: space-between;
      background: #f5f5f5;
      margin-bottom: 1px;
      align-items: center;

      .size-label {
        background: #a1c9a4;
        color: #fff;
        width: 40px;
        padding: 5px;
        text-align: center;
      }

      li {
        width: 30%;
        text-align: center;
        margin-bottom: 3px;
      }
    }

    .price {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.01em;
      text-align: center;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .ant-btn.ant-btn-primary {
      width: 100%;
      position: relative;
      padding: 0px 16px;
      margin-bottom: 16px;
      min-height: 40px;

      .anticon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    ul.tablelist {
      height: 100%;
      display: flex;
      flex-direction: column;

      li {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        border-bottom: 1px solid #ebebeb;
        padding: 6px 0px;
        min-height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.green {
          color: #129b12;
        }

        &.orange {
          color: #bf6101;
        }

        &.descp {
          font-weight: normal;
          color: #6f6f6f;
          flex: 1 1 auto;
          min-height: 85px;
        }

        &:last-child {
          min-height: 85px;
        }

        &:first-child {
          min-height: 85px;
        }
      }
    }
  }

  .compare_panel {
    .inside {
      background: #f8f8f8;
      height: 100%;
      display: flex;
      flex-direction: column;

      .topdiv {
        background: #f5f5f5;
        padding: 0px 16px;
        flex: 1 1 auto;

        h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 36px;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          text-transform: capitalize;
          color: #343434;
          margin: 0;
          margin-top: 10px;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #000000;
          opacity: 0.5;
          display: block;
          margin-bottom: 16px;
        }

        ul {
          margin: 0;
          padding: 0;
          margin-left: 10px;

          li {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            color: #343434;
            margin-bottom: 10px;
            position: relative;

            &::before {
              content: "-";
              position: absolute;
              left: -10px;
              top: 0px;
            }
          }
        }
      }

      .bottomdiv {
        padding: 0px 16px;

        ul {
          height: 100%;
          display: flex;
          flex-direction: column;

          li {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            border-bottom: 1px solid #f7f7f7;
            padding: 6px 0px;
            min-height: 58px;
            display: flex;
            align-items: center;

            &:last-child {
              min-height: 85px;
            }

            &:first-child {
              min-height: 85px;
            }
          }
        }
      }
    }
  }

  .ant-collapse {
    background: none;
    border: 0;
    min-width: 947px;
  }

  .ant-collapse>.ant-collapse-item {
    border: 0;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    padding-left: 16.66666667%;
    background: #a0c8a5;
    position: relative;
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;
    color: #fff;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: calc(16.66666667% - 6px);
      background: #f5f5f5;
    }
  }

  .ant-collapse-content {
    border-top: 0;
  }

  .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: auto;
    font-size: 12px;
    right: 10px;
  }
}

.prod-detail {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 32px;
  margin: 16px 0px 16px 0px;
  flex-wrap: nowrap;

  .left_clm {
    .image-gallery {
      margin-bottom: 16px;
    }

    .image-gallery-thumbnail {
      border: 1px solid transparent;
      width: 150px;
    }

    .image-gallery-thumbnail.active {
      border: 1px solid #a0c8a5;
    }

    .image-gallery-thumbnails-wrapper.left {
      width: 150px;
    }

    .image-gallery-slide-wrapper.left {
      display: inline-block;
      width: calc(100% - 160px);
    }

    .image-gallery-slides {
      line-height: 0;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      text-align: center;
      min-height: 592px;
      max-height: 592px;
      display: flex;
      justify-content: center;
    }

    .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail,
    .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
      margin-left: 0;
      margin-top: 10px;
    }

    .image-gallery-thumbnails-wrapper.left,
    .image-gallery-thumbnails-wrapper.right {
      margin: 0px 10px 0px 0px;
    }

    .prodtl {
      margin-bottom: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      margin-top: 50px;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;
      }

      ul {
        margin-top: 50px;

        li {
          background: url(../../images/arrow2.png) no-repeat left top 5px;
          padding-left: 20px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .right_clm {
    padding-left: 32px;

    .impactdtl {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 16px;
      margin-bottom: 24px;

      .impwrap {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 16px;

        .icod {
          margin-right: 10px;

          img {
            width: 32px;
          }
        }

        .lbld {
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 16px;
          color: #000000;

          h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #44514e;
            margin: 0;
            margin-bottom: 3px;
          }
        }
      }
    }

    .row1 {
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 16px;
      margin-bottom: 16px;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;
        margin: 0;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 25px;
        color: #414141;
        margin: 0;
      }

      .price {
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 32px;
        margin-top: 10px;
        display: inline-block;
      }
    }

    .sizediv {
      margin-bottom: 24px;
      min-height: 80px;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;

        a {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #a0c8a5;
          text-transform: none;
          margin-left: 10px;
          display: inline-block;
        }
      }

      .sizeselect {
        .ant-radio-button-wrapper {
          border: 1px solid #c3c3c3;
          padding: 0;
          width: 50px;
          height: 50px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 10px;

          &:not(:first-child)::before {
            display: none;
          }

          &:hover {
            position: relative;
            color: #a1c9a4;
          }
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
          color: #fff;
          background-color: #a1c9a4;
          border: 1px solid #a1c9a4;
        }
      }
    }

    .qtydiv {
      margin-bottom: 24px;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;
      }
    }

    .btnrow {
      margin-bottom: 24px;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 24px;
    }

    .specification {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #343434;
      border-bottom: 1px solid #e0e0e0;
      padding-bottom: 24px;
      margin-bottom: 32px;

      .ant-row {
        padding: 7px 0px;

        strong {
          min-width: 50px;
          display: inline-block;
        }
      }
    }

    .socialimp {
      margin-bottom: 24px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #000000;
      }

      ul {
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f1f1f1;
          padding: 16px 0px;
          padding-right: 16px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;

          .anticon {
            margin-right: 16px;

            &.anticon-close {
              color: #bf6101;
            }

            &.anticon-check {
              color: #129b12;
            }
          }
        }
      }
    }
  }
}

.landing_content {
  .coursol_row {
    padding: 24px 0px;
    border-bottom: 2px solid #e0f3e3;
    margin-bottom: 24px;

    &.howdo {
      border: 0;
      margin: 0;
    }
  }

  .ant-carousel {
    height: 100%;

    .slick-slider {
      height: 100%;
    }

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .slick-slide {
      height: 100%;

      >div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 48px;
      line-height: 56px;
      color: #000000;
      margin: 0;
    }

    .slick-dots {
      display: none !important;
    }

    .slick-arrow {
      visibility: visible;
      z-index: 9;
      color: #181314;
      font-size: 26px;
      width: 25px;
      height: 25px;

      &.slick-next {
        right: 20px;
        top: calc(50% - 30px);
      }

      &.slick-prev {
        left: 20px;
        top: calc(50% - 30px);
      }
    }
  }

  .newsdiv {
    background: #e0f3e3;
    padding: 10px;

    .imgdiv {
      width: 100%;
      margin-bottom: 5px;

      img {
        width: 100%;
      }
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #181314;
      margin: 0;
      margin-bottom: 12px;
    }

    .blkbtnsml {
      background: #181314;
      border-radius: 2px;
      height: 20px;
      min-height: 20px;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      color: #ffffff;
      width: 76px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .collection {
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 21px;
      color: #181314;
      margin-bottom: 16px;
    }

    .collect_div {
      background: #fefefe;
      border: 1px solid #b3d3b7;
      box-sizing: border-box;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.078137);
      border-radius: 2px;
      padding: 10px;
      margin-bottom: 30px;
      height: calc(100% - 26px);
      display: flex;
      flex-direction: column;

      .imgdiv {
        width: 100%;
        margin-bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        img {
          width: 100%;
        }
      }

      .favrout {
        position: absolute;
        right: 0;
        top: 0;
        background: #fff;
        width: 32px;
        height: 32px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f1f1f1;
        font-size: 18px;

        .ant-checkbox-inner {
          display: none;
        }

        .anticon {
          color: #181314;
        }

        .ant-checkbox+span {
          display: flex;
        }

        .fill {
          display: none;
        }

        .ant-checkbox.ant-checkbox-checked+span>.fill {
          display: inline-block;
        }

        .ant-checkbox.ant-checkbox-checked+span>.nonfill {
          display: none;
        }
      }

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #181314;
        margin: 0;
        margin-bottom: 3px;
        position: relative;
        padding-right: 32px;
        flex: auto;

        a {
          color: #181314;
        }

        span {
          cursor: pointer;
        }
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        min-height: 38px;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        padding-right: 32px;
      }

      .btndiv {
        display: flex;
        justify-content: space-between;

        .blkbtnsml {
          background: #181314;
          border-radius: 2px;
          height: 24px;
          min-height: 24px;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 13px;
          text-align: center;
          color: #ffffff;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.indicatorpage {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 32px;
  margin: 16px 0px 16px 0px;
  flex-wrap: nowrap;

  .ant-space {
    display: inline-flex;
  }

  ol {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    margin-left: 16px;

    li {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
}

.favprolist {
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 16px;
  margin-bottom: 16px;

  .ant-col {
    display: flex;
  }

  .thumbimg {
    margin-right: 16px;
    padding: 5px;
    border: 1px solid #f1f1f1;
    cursor: pointer;

    img {
      max-width: 100px;
    }
  }

  .discppro {
    padding-right: 40px;
    flex: auto;
    position: relative;

    h3 {
      margin: 0;
      margin-bottom: 5px;
      font-size: 18px;
      cursor: pointer;
    }

    p {
      margin: 0;
      margin-bottom: 5px;
      font-size: 16px;
    }

    .valuerow {
      display: flex;
      font-size: 16px;

      label {
        color: #7e7e7e;
        min-width: 100px;
        margin-right: 10px;
      }
    }

    .dltbtn {
      position: absolute;
      right: 0;
      top: 0;
      color: #7e7e7e;
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      border: 1px solid #f1f1f1;
      border-radius: 50%;

      &:hover {
        background: #a1c9a4;
        color: #fff;
      }

      .anticon {
        font-size: 16px;
      }
    }
  }
}

.howwedo {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 32px;
  margin: 16px 0px 16px 0px;
  flex-wrap: nowrap;
  font-size: 20px;

  .infographic {
    display: flex;

    .infographicsimg {
      margin-right: 16px;

      img {
        max-width: 400px;
      }
    }

    .infographics-content {
      margin-bottom: 32px;

      h2 {
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        line-height: 54px;
        color: #000000;
        margin-bottom: 10px;
      }

      .info-icons {
        text-align: center;
        display: flex;
        text-align: center;
        margin: 32px 0px;

        >div {
          margin-right: 50px;
        }

        .icons {
          img {
            max-width: 100px;
          }
        }

        h3 {
          margin: 0;
          margin-top: 5px;
          font-size: 18px;
        }
      }
    }
  }

  a {
    color: rgba(0, 0, 0, 0.85);
  }

  h1 {
    margin: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
    color: #000000;
    margin-bottom: 10px;
  }

  .normal_div {
    padding-left: 80px;
    margin-bottom: 32px;

    .img-wrapper {
      display: flex;
      justify-content: center;
      flex: auto;
      flex-direction: column;
      align-items: center;

      .img-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        text-align: center;

        span {
          &:first-child {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 26px;
            letter-spacing: 0.01em;
            text-transform: capitalize;
            color: #343434;
          }

          &:last-child {
            color: #41ac60;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 26px;
            margin: 0;
          }
        }
      }
    }
  }

  ul.mainul {
    margin: 0;
    padding: 0;
    word-break: break-word;

    >li {
      margin-bottom: 32px;
      padding-left: 80px;
      position: relative;

      .count {
        position: absolute;
        left: 0;
        top: -10px;
        background: #c4c4c4;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 45px;
        color: #ffffff;
      }

      h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin: 0;
      }
    }
  }

  &.mark-description {
    font-size: 14px;
    line-height: 22px;
    font-weight: normal;

    h1 {
      font-size: 20px;
      line-height: 28px;
    }

    .infographic .infographicsimg img {
      max-width: 225px;
    }

    .infographic .infographics-content .info-icons .icons img {
      max-width: 44px;
    }

    .infographic .infographics-content .info-icons h3 {
      font-size: 14px;
      line-height: 18px;
    }

    .normal_div {
      padding-left: 0;
      margin-bottom: 0;
    }

    .ant-col {
      padding-bottom: 0 !important;
      word-break: break-word;
    }

    .infographic .infographics-content {
      margin-bottom: 0;
    }

    .infographic .infographics-content .info-icons {
      margin-bottom: 0;
    }

    .infographic .infographics-content .info-icons>div {
      margin-right: 16px;
    }
  }
}

.anyqa {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 32px;
  word-break: break-word;

  .lc {
    padding: 16px;
    padding-right: 32px;

    a {
      color: #000000;
    }
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin: 0;
    margin-bottom: 10px;
  }

  .rc {
    img {
      max-width: 332px;
    }
  }
}

.privacypage {
  font-size: 16px;
  word-break: break-word;

  ol {
    margin: 0;
    padding: 0;
    padding-left: 24px;
  }

  h5 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
  }

  h6 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 5px;
  }

  a {
    color: #72a276;
  }

  li {
    margin-bottom: 24px;
  }
}

.faqpage {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 32px;
  margin: 16px 0px 16px 0px;
  flex-wrap: nowrap;

  .ant-space {
    display: block;
  }

  .ant-collapse-item-active {
    background: #a1c9a4;
    color: #fff;
  }

  .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header {
    color: #fff;
  }

  .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding: 12px 16px;
    padding-right: 40px;
    font-weight: 500;
    font-size: 16px;
  }
}

.meetteam {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 32px;
  margin: 16px 0px 16px 0px;
  flex-wrap: nowrap;

  .pagename {
    padding: 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    padding: 0px 0 16px 0;
    border-bottom: 1px solid #eaeaea;
  }

  .teamwrap {
    margin-top: 32px;
    text-align: center;

    .imgdiv {
      width: 100%;
      margin-bottom: 16px;

      img {
        width: 100%;
        max-width: 235px;
        border-radius: 50%;
      }
    }

    h3 {
      line-height: 24px;
      font-size: 20px;
      font-weight: 400;
      margin: 0;
      margin-bottom: 5px;
    }

    h5 {
      line-height: 24px;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      margin: 0;
    }
  }
}

.brandpage {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 32px;
  margin: 16px 0px 16px 0px;
  flex-wrap: nowrap;

  .pagename {
    padding: 10px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    padding: 0px 0 16px 0;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 16px;
  }

  .brandwrap {
    margin-top: 16px;
    text-align: center;

    .imgdiv {
      width: 100%;
      cursor: pointer;

      img {
        width: 100%;
        max-width: 235px;
      }
    }
  }

  .brand-search-wrap {
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 16px;
    padding-bottom: 16px;

    .inside {
      max-width: 500px;
      margin: 0 auto;
    }
  }
}

.ant-back-top {
  background: #a0c8a5;
  color: #fff;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  width: 50px;
  height: 50px;
  right: 32px;
  bottom: 32px;

  .anticon {
    transform: rotate(-90deg);
  }
}

@media only screen and (max-width: 1100px) {
  .productPage .product_content .blogbanner .morecontent .left_cont {
    max-width: 460px;
  }

  .howwedo.mark-description .infographic .infographicsimg img {
    max-width: 175px;
  }
}

@media only screen and (max-width: 991px) {
  .productPage .filter_left {
    display: none;
  }

  .productPage .filter_left_mob {
    display: block;

    .category_one {
      h3 {
        color: #000;
      }

      .impact-filter-div {
        .ant-radio-inner::after {
          background-color: #a1c9a4;
        }
      }
    }
  }

  .productPage .product_content {
    padding: 0;
  }

  .compare-product .compare_panel .inside .topdiv h3 {
    font-size: 20px;
  }

  .prod-detail .left_clm .image-gallery-thumbnails-wrapper.left {
    width: 100px;
  }

  .prod-detail .left_clm .image-gallery-thumbnail {
    width: 100px;
  }

  .prod-detail .left_clm .image-gallery-slide-wrapper.left {
    width: calc(100% - 110px);
  }

  .prod-detail {
    padding: 16px;
  }

  .prod-detail .right_clm {
    padding-left: 16px;
  }

  .prod-detail .left_clm .impactdtl {
    padding-left: 110px;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .prod-detail .left_clm .image-gallery-slides {
    min-height: inherit;
    max-height: inherit;
  }

  .image-gallery-content.left .image-gallery-slide .image-gallery-image,
  .image-gallery-content.right .image-gallery-slide .image-gallery-image {
    max-height: inherit;
  }

  .productPage .category_one .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #a1c9a4;
  }

  .howwedo .infographic .infographicsimg img {
    max-width: 250px;
  }

  .howwedo .infographic .infographics-content .info-icons>div {
    margin-right: 32px;
  }

  .howwedo.mark-description .infographic {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .productPage .product_content .blogbanner .morecontent {
    flex-wrap: wrap;
  }

  .productPage .product_content .blogbanner .morecontent .right_cont .biglink {
    margin-top: 16px;
  }

  .productPage .product_content .blogbanner .morecontent .right_cont h5 {
    margin-top: 16px;
  }

  .productPage .product_content .blogbanner .morecontent .left_cont {
    max-width: 100%;
  }

  .productPage .product_content .proList {
    padding: 16px;
  }

  .productPage .product_content .proList .title {
    font-size: 24px;
    padding: 0px 0 10px 0;
  }

  .landing_content .ant-carousel h3 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 16px;
  }

  .landing_content .collection .collect_div {
    margin-bottom: 16px;
  }

  .prod-detail .left_clm .impactdtl {
    padding-left: 0;
  }

  .prod-detail .right_clm .row1 h3 {
    font-size: 20px;
  }

  .prod-detail .right_clm .row1 p {
    font-size: 16px;
    line-height: 22px;
  }

  .prod-detail .right_clm .row1 .price {
    font-size: 20px;
    line-height: 20px;
  }

  .howwedo h1 {
    font-size: 22px;
    line-height: 32px;
  }

  .howwedo {
    font-size: 16px;
    padding: 16px;
  }

  .howwedo ul.mainul>li h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .howwedo ul.mainul>li .count {
    top: -2px;
    width: 30px;
    height: 30px;
    font-size: 16px;
  }

  .howwedo ul.mainul>li {
    margin-bottom: 20px;
    padding-left: 42px;
  }

  .howwedo .normal_div {
    padding-left: 42px;
    margin-bottom: 20px;
  }

  .anyqa h3 {
    font-size: 18px;
    line-height: 24px;
  }

  .anyqa {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .anyqa .rc img {
    max-width: 190px;
  }

  .productPage .product_content .proList .prowrap .Description {
    min-height: 95px;
  }

  .howwedo .infographic .infographics-content h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .howwedo .infographic .infographics-content .info-icons .icons img {
    max-width: 60px;
  }

  .howwedo .infographic .infographics-content .info-icons h3 {
    font-size: 16px;
  }

  .howwedo .infographic {
    display: flex;
    flex-wrap: wrap;
  }

  .howwedo .infographic .infographicsimg {
    margin-right: 16px;
    text-align: center;
    width: 100%;
  }

  .productPage .product_content .proList .prowrap ul li {
    min-width: inherit;
    padding: 0px 3px;
  }

  .productPage .product_content .proList .prowrap ul li .icondiv img {
    max-width: 30px;
  }

  .productPage .product_content .proList .prowrap ul li h3 {
    font-size: 13px;
  }

  .productPage .product_content .proList .prowrap ul li h4 {
    font-size: 12px;
  }

  .howwedo.mark-description .infographic .infographics-content .info-icons {
    margin-bottom: 32px;
  }
}

@media only screen and (max-width: 450px) {
  .productPage .product_content .blogbanner .morecontent .left_cont .inr_cont {
    flex-wrap: wrap;
  }

  .productPage .product_content .blogbanner .morecontent .left_cont .inr_cont .imgcont {
    width: 100%;
    margin-top: 16px;
  }

  .productPage .product_content .proList .bottomrow {
    flex-wrap: wrap;
  }

  .ant-pagination {
    width: 100%;
    margin-top: 10px;
    text-align: right;
  }

  .productPage .product_content .proList .prowrap .Description h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .productPage .product_content .proList .prowrap .Description {
    min-height: 105px;
  }

  .productPage .product_content .proList .prowrap ul li .icondiv img {
    max-width: 30px;
  }

  .productPage .product_content .proList .prowrap .compare label {
    font-size: 12px;
    line-height: normal;
  }

  .productPage .product_content .proList .prowrap .imagdiv {
    min-height: 155px;
  }

  .productPage .product_content .proList .prowrap .price {
    width: 100%;
  }

  .productPage .product_content .proList .prowrap .price_compare_wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  .ant-back-top {
    font-size: 16px;
    width: 36px;
    height: 36px;
  }

  .landing_content .collection .collect_div h3 {
    font-size: 14px;
  }

  .productPage .product_content .proList {
    padding: 10px;
  }

  .indicatorpage {
    padding: 10px;
  }

  body .ant-layout.front section.tabsection .ipact_ind .water_usages {
    padding: 10px;
  }

  body .ant-layout.front section.tabsection .ipact_ind .water_usages .headerTabButtons {
    flex-wrap: wrap;
  }

  body .ant-layout.front section.tabsection .ipact_ind .water_usages .ant-btn.ant-btn-primary.blkbtn {
    margin-top: 5px;
  }

  .landing_content .ant-carousel h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0px;
  }

  body .ant-layout.front section.tabsection .ipact_ind .water_usages h3 {
    font-size: 18px;
  }

  .productPage .product_content .proList .prowrap ul li h3 {
    line-height: 18px;
    min-height: 36px;
  }
}

.skeleton-img {
  width: 100% !important;
  height: 300px !important;
  margin-bottom: 30px;
}

.skeleton20 {
  width: 100% !important;
  height: 20px;
  margin-bottom: 35px;
}

.skeleton30 {
  width: 100% !important;
  height: 25px;
  margin-bottom: 20px;
}

.skeleton50 {
  width: 100% !important;
  height: 50px;
  margin-bottom: 10px;
}

.skeleton100 {
  width: 100% !important;
  height: 100px !important;
  margin-bottom: 10px;
}

.hithere {
  background: #ddebde;
  border: 1px solid #ddebde;
  padding: 32px;
  margin: 16px 0px 16px 0px;
  flex-wrap: nowrap;

  font-size: 16px;
  font-weight: normal;
  color: #000;
  line-height: 20px;

  h3 {
    font-size: 18px;
    color: #000;
    margin: 0;
    font-weight: 700;
    margin-bottom: 5px;
  }
}