// @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');
// font-family: 'Heebo', sans-serif;
@import url("../../fonts/fonts.css");
@import "colors.scss";
// font-family: 'Euclid Circular A';
html {
  height: 100%;
}
body {
  height: 100%;
}
body #root {
  height: 100%;
}
iframe {
  display: none;
}
a {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.bg-black {
  background: #181314 !important;
}
.ant-menu-submenu-popup {
  .contact-btn {
    cursor: pointer;
    background: none;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-transform: none;
    padding: 0;
  }
}
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-inline:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border: 0;
  color: #fff;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  display: none;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #fff;
}
body {
  .ant-layout {
    font-family: "Euclid Circular A";
    background: none;
    height: 100%;
    position: relative;
    z-index: 9;
    &.front {
      height: auto;
      background: #f8f8f8;
      .container_small {
        > .ant-row {
          flex-wrap: nowrap;
          justify-content: space-between;
        }
        .logowrp {
          flex: 0 0 190px;
          display: flex;
          align-items: center;
          .desktopmenu {
            display: inline-block;
          }
          .mobmenu {
            display: none;
            .anticon {
              color: #fff;
              font-size: 38px;
              margin-top: 14px;
              vertical-align: top;
            }
          }
          .ant-menu-inline {
            border: 0;
            background: none;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            color: #ffffff;
            max-width: 100%;
            white-space: nowrap;
            display: flex;
            a {
              color: #ffffff;
            }
          }
          .ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item:hover {
            color: #000;
            border-bottom: 2px solid #000;
          }
          .ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item-selected {
            color: #000;
            border-bottom: 2px solid #000;
          }
          .ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item:hover a {
            color: #000;
          }
          .ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item-selected a {
            color: #000;
          }
          .ant-menu-inline:not(.ant-menu-dark) > .ant-menu-item {
            margin: 0px 11px;
            padding: 5px !important;
            height: auto;
            line-height: normal;
            width: auto;
            border-bottom: 2px solid transparent;
          }
          .contact-btn {
            cursor: pointer;
            background: none;
            border: none;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-transform: uppercase;
            padding: 0;
          }
        }
      }
      section.tabsection {
        margin-top: 86px;
        background: #a1c9a4;
        .ipact_ind {
          max-width: 605px;
          margin: 0 auto 25px;
          text-align: center;
          .tabhead {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #181314;
            padding: 0px 0px 16px 0px;
          }
          .normal_text {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            max-width: 500px;
            margin: 0 auto;
            margin-bottom: 27px;
          }
          .transparency {
            background: #edffef;
            padding: 16px;
            display: flex;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            margin-bottom: 36px;
            .iconbox {
              padding-right: 16px;
            }
            h3 {
              margin: 0;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 18px;
              color: #000000;
            }
          }
          .water_usages {
            background: #edffef;
            padding: 20px;
            margin-bottom: 20px;
            .water_title {
              display: flex;
              align-items: center;
              padding-bottom: 10px;
              border-bottom: 2px solid #a0c8a5;
              margin-bottom: 12px;
            }
            .iconbox {
              padding-right: 10px;
              img {
                width: 36px;
              }
            }
            h3 {
              margin: 0;
              font-style: normal;
              font-weight: bold;
              font-size: 22px;
              line-height: 21px;
              color: #181314;
            }
            .ant-row {
              flex-wrap: nowrap;
            }
            .l_col {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #000000;
              padding-right: 24px;
              h4 {
                margin: 0;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                color: #181314;
                margin-bottom: 5px;
              }
              .blkbtnsml {
                background: #181314;
                color: #fff;
                border-radius: 2px;
                height: 20px;
                min-height: 20px;
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                line-height: 13px;
                text-align: center;
                color: #ffffff;
                width: 95px;
              }
            }
            .headerTabButtons {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
            .ant-btn.ant-btn-primary.blkbtn {
              background: #181314;
              color: #fff;
              border-radius: 2px;
              height: 28px;
              min-height: 28px;
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 20px;
              text-align: center;
              color: #ffffff;
              max-width: 138px;
            }
            .r_col {
              min-width: 210px;
              display: flex;
              flex-direction: column;
              .branderap {
                flex: 1 1 auto;
              }
              h4 {
                margin: 0;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                color: #181314;
                margin-bottom: 5px;
              }
              a {
                display: block;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #000000;
                padding-left: 16px;
              }
              .ant-btn.ant-btn-primary {
                background: #181314;
                color: #fff;
                border-radius: 2px;
                height: 31px;
                min-height: 31px;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                max-width: 138px;
              }
            }
          }
          .btn_row_1 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 26px;
            .ant-btn.ant-btn-primary {
              background: #181314;
              color: #fff;
              border-radius: 2px;
              height: 31px;
              min-height: 31px;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              color: #ffffff;
            }
          }
        }
        .ant-tabs-top > .ant-tabs-nav {
          &::before {
            border-bottom: 2px solid #fff;
          }
          &::after {
            width: 2px;
            height: 16px;
            content: "";
            background: #fff;
            position: absolute;
            right: 7px;
            bottom: -7px;
          }
        }

        .ant-tabs-nav-list {
          &::after {
            width: 3px;
            height: 16px;
            content: "";
            background: #a1c9a4;
            position: absolute;
            right: 16px;
            bottom: -7px;
          }
        }
        .ant-tabs-tab {
          padding: 0px 0px 18px 0px;
          margin: 0px 5px;
          &:nth-last-child(2) {
            margin: 0;
          }
          &:first-child {
            margin: 0;
            width: 0;
            height: 0;
            overflow: hidden;
            display: none;
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs .ant-tabs-nav .ant-tabs-nav-list {
          justify-content: space-between;
          display: flex;
          width: 100%;
        }
        .ant-tabs-top > .ant-tabs-nav {
          margin: 0px 0px 27px 0px;
        }
        .icon-wrapper {
          position: relative;
          display: flex;
          flex: auto;
          align-self: stretch;
          white-space: nowrap;
          transform: translate(0);
          margin: 0;
          ul {
            display: flex;
            position: relative;
            justify-content: space-between;
            width: 100%;
            li {
              display: inline-flex;
              align-items: center;
              border: 0;
              outline: 0;
              position: relative;
              padding: 0px 0px 18px;
              margin: 0px 5px;
              cursor: pointer;
              &::nth-last-child(2) {
                margin: 0;
              }
              .cardicon {
                max-width: 100%;
              }
            }
            &:after {
              width: 3px;
              height: 16px;
              content: "";
              background: #a1c9a4;
              position: absolute;
              right: 16px;
              bottom: -7px;
            }
          }
          &:before {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            border-bottom: 2px solid #fff;
            content: "";
          }
          &:after {
            width: 2px;
            height: 16px;
            content: "";
            background: #fff;
            position: absolute;
            right: 7px;
            bottom: -7px;
          }
        }
      }
      .ant-layout-header {
        .rightside {
          align-items: center;
          flex: 0 0 190px;
          .anticon {
            color: #fff;
            font-size: 24px;
          }
          .socialicon {
            color: #181314;
            display: inherit;
            margin-right: 16px;
            display: flex;
            align-items: center;
            .anticon {
              color: #181314;
              margin-left: 10px;
            }
            a {
              display: inline-block;
              span {
                display: inherit;
              }
            }
            a.wishlisticon {
              background: rgba(255, 255, 255, 0.7);
              width: 26px;
              height: 26px;
              border-radius: 50%;
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              .anticon {
                font-size: 16px;
                margin: 0;
                display: inline-block;
              }
            }
          }
        }

        // .ant-tabs-tab-btn span{
        //     img:last-child{
        //         display: none;
        //     }
        //     img:first-child{
        //         display: inline-block;
        //     }
        // }
        // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn span{
        //     img:last-child{
        //         display: inline-block;
        //     }
        //     img:first-child{
        //         display: none;
        //     }
        // }
      }
    }
  }
  .ant-layout-content {
    min-height: 100%;
  }
  .ant-layout-footer {
    background: $darkgreen;
    padding: 0px;
    color: $white;
    .footer_sec_1 {
      background: #424e4c;
      padding: 32px 0px;
      .newsletter {
        display: flex;
        align-items: center;
        .lcol {
          h3 {
            color: #ffffff;
            font-style: normal;
            font-weight: 300;
            font-size: 32px;
            line-height: 26px;
            margin: 0;
            margin-bottom: 10px;
          }
          p {
            margin: 0;
            font-weight: 300;
          }
        }
        .rcol {
          flex: auto;
          text-align: center;
          .subscribe {
            background: #a0c8a5;
            min-height: 44px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #ffffff;
            border: 0;
            border-radius: 0;
            display: inline-flex;
            align-items: center;
            padding: 10px 16px;
          }
        }
      }
    }
    &.landing_footer {
      background: none;
      color: #000;
    }
    .footer_sec_2 {
      padding: 32px 0px;
      background: url(../../images/worldmap.webp) no-repeat center;
      background-size: cover;
      .logo {
        margin-bottom: 30px;
        display: inline-block;
      }
      .socialicons {
        li {
          display: inline-block;
          margin-right: 10px;
        }
        a {
          font-size: 32px;
          color: $lightgreen;
          &:hover {
            color: $white;
          }
        }
      }
      h3 {
        color: #ffffff;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 16px;
        text-transform: uppercase;

        &::after {
          content: "";
          width: 100%;

          height: 1px;
          border-bottom: 2px solid #a1c9a4;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
      p {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        margin: 0;
        color: rgba(255, 255, 255, 0.8);
      }
      .link {
        color: $lightgreen;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 27px;
        letter-spacing: 0.01em;
      }
      .cnctrow {
        display: flex;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        &.firstd {
          align-items: flex-start;
        }
        strong {
          display: block;
        }
        .icon {
          margin-right: 10px;
          img {
            width: 24px;
          }
        }
      }
      .updtaes_row {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: rgba(255, 255, 255, 0.8);
        h5 {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.5px;
          color: rgba(255, 255, 255, 0.8);
          margin: 0;
        }
        .date {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      ul.footerlinks {
        margin: 0;
        padding: 0;
        li {
          width: 100%;
          float: left;
          position: relative;
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          padding: 8px 0px;
          &:last-child {
            border: 0;
          }
          a {
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 33px;
            letter-spacing: 1px;
            color: rgba(255, 255, 255, 0.9);
            &:hover {
              color: $lightgreen;
            }
          }
          // &::before {
          //     content: "";
          //     background: url(../../images/bullet-1.png) no-repeat center;
          //     width: 10px;
          //     height: 10px;
          //     position: absolute;
          //     left: 0;
          //     top: 10px;
          // }
        }
      }
      .footerrow {
        .copyright {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: #000000;
          .logotext {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            display: inline-block;
            margin-right: 5px;
          }
          a {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
          }
        }
        .footerright {
          display: flex;
          justify-content: flex-end;
          a.subscribe {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #000000;
            margin-right: 54px;
    font-display: fallback;
          }
          .anticon {
            color: #fff;
            font-size: 24px;
          }
          .socialicon {
            color: #181314;
            display: inline-block;
            margin-right: 0;
            .anticon {
              color: #181314;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .ant-dropdown-menu {
    border-radius: 5px;
    padding: 16px;
    margin-top: 10px;
    position: relative;
    &::before {
      position: absolute;
      right: 10px;
      top: -7px;
      display: inline-block;
      width: 0;
      height: 0;
      vertical-align: middle;
      border-bottom: 10px solid $white;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      content: "";
    }
    .ant-dropdown-menu-item {
      border-bottom: 1px solid #f1f1f1;
      .contact-btn {
        cursor: pointer;
        background: none;
        border: none;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        padding: 0;
        font-size: 14px;
        text-transform: none;
      }
      a {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: $darkgray;
        display: flex;
        align-items: center;
        .menu-icon {
          font-size: 18px;
          margin-right: 7px;
          width: 20px;
          color: $darkgray;
        }
      }
      &:last-child {
        border: 0;
      }
    }
  }
  .ant-layout-header {
    background: $lightgreen;
    border-image: none;
    height: 86px;
    padding: 10px 0px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    .logo {
      margin-right: 32px;
      display: inline-block;
    }

    .rightside {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
    }
    .headerbtn {
      height: 44px;
      margin-right: 10px;
      background: $white;
      width: 44px;
      overflow: hidden;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $darkgray;
      font-size: 20px;
      min-height: inherit;
    }
    .profilebtn {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      .imgd {
        border-radius: 50%;
        overflow: hidden;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        img {
          height: auto;
          width: 100%;
        }
      }

      .imgd.ant-dropdown-trigger.profile-placeholder {
        background: #fff;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .imgd.ant-dropdown-trigger.profile-placeholder img {
        width: 45px !important;
        border-radius: 50%;
      }
    }
    .topmenu {
      display: inline-block;
      a {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $white;
        text-transform: uppercase;
        padding: 10px 16px;
        border-bottom: 2px solid $lightgreen;
        margin: 0px 5px;
        &.active,
        &:hover {
          color: $black;
          border-bottom: 2px solid $black;
        }
      }
    }

    .ant-btn-primary {
      background: $white;
      color: $black;
      min-height: 44px;
      border-radius: 0;
      line-height: normal;
      margin: 0;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .anticon {
        font-size: 22px;
        display: inline-flex;
      }
    }

    .searchhwrap {
      min-height: 44px;
      max-height: 44px;
      display: inline-block;
      vertical-align: top;
      margin-right: 10px;
      .ant-input-affix-wrapper {
        border: 0;
        min-height: 44px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        box-shadow: none;
        min-width: 320px;
        vertical-align: top;
        border-radius: 5px;
        .ant-input-suffix {
          font-size: 20px;
        }
      }
    }
  }

  .ant-layout-sider {
    position: fixed;
    background: $darkgreen;
    left: 0;
    top: 86px;
    z-index: 99;
    height: calc(100vh - 86px);
    -webkit-transition: none;
    transition: none;
    .ant-layout-sider-children {
      overflow: auto;
      &::-webkit-scrollbar {
        width: 1px;
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        background: hsla(0, 0%, 100%, 0.2);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px hsla(0, 0%, 100%, 0.05);
      }
      &::-webkit-scrollbar-track {
        background: hsla(0, 0%, 100%, 0.15);
        border-radius: 3px;
        -webkit-box-shadow: inset 0 0 5px rgba(37, 37, 37, 0.05);
      }
    }
    .ant-layout-sider-zero-width-trigger {
      background: $darkgreen;
    }
    &.ant-layout-sider-zero-width {
      .ant-layout-sider-children {
        padding: 0;
        overflow: hidden;
      }
    }
    .sidebar_menu {
      padding: 16px;
    }
    .profilebar {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      color: $white;
      padding: 16px;
      display: flex;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.1);
      .imgd {
        border-radius: 50%;
        overflow: hidden;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        margin-right: 10px;
        img {
          height: auto;
          width: 100%;
        }
      }
    }

    .navigate {
      li {
        &.active a {
          color: $white;
        }
      }
      a {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        color: rgba($white, 0.5);
        padding: 16px 0px;
        display: flex;
        letter-spacing: 0.5px;
        cursor: pointer;
        align-items: center;
        border-bottom: 1px solid rgba($white, 0.1);
        i {
          margin-right: 7px;
          min-width: 26px;
          height: 26px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
        }
        &:hover {
          color: $white;
        }
      }
    }
  }
}

.hide {
  display: none !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
.mobilemenu {
  display: none;
  a {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #8e8e8e;
    text-transform: uppercase;
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
    text-align: left;
    &.active,
    &:hover {
      color: $black;
      border-bottom: 1px solid #f1f1f1;
    }
  }
  .ant-drawer-body {
    padding: 16px;
  }
}
.scbscribe_modal .ant-modal-content {
  background: #edffef;
  padding: 32px 60px;
  .ant-modal-header {
    padding: 0;
    background: none;
    border-bottom: 0;
    border-radius: 0;
    text-align: center;
    margin-bottom: 32px;
    .ant-modal-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #181314;
    }
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
  .ant-input {
    border: 0;
    height: 26px;
    padding: 0px 15px;
    min-height: 26px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 18px;
  }
  .note {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: #181314;
    margin-top: 24px;
    text-align: center;
    margin-bottom: 24px;
  }
  .ant-modal-footer {
    padding: 0;
    text-align: center;
    background: transparent;
    border-top: 0;
    border-radius: 0;
    margin-top: 32px;
  }
  .ant-btn.ant-btn-primary {
    width: 62px;
    height: 30px;
    background: #181314;
    min-height: 30px;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  .ant-modal-close-x {
    display: block;
    font-size: 14px;
    background-color: #181314;
    color: #fff;
    margin: 5px;
    width: 30px;
    height: 30px;
    padding: 0;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 26px;
  }
}
.not-found {
  width: 100%;
  border-top: 1px solid #f1f1f1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-top: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 1100px) {
  body {
    .ant-layout-header {
      .ant-input-affix-wrapper {
        min-width: 225px;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  body {
    .ant-layout-header {
      .topmenu {
        display: none;
      }
    }
  }
  .mobilemenu {
    display: inline-flex;
    height: 44px;
  }
  body .ant-layout.front .container_small > .ant-row {
    flex-wrap: wrap;
  }
  body .ant-layout.front .container_small .tabcol {
    width: 100%;
  }
  body .ant-layout-header .ipact_ind {
    max-width: 605px;
    margin: 0 auto;
  }
  body .ant-layout-header .ipact_ind .tabhead {
    padding: 0 0px 16px 0px;
  }
  body .ant-layout.front .ant-layout-header .rightside {
    flex: 0 0 0;
  }

  body .ant-layout.front .container_small .logowrp {
    flex: 1 auto;
    justify-content: space-between;
  }
  body .ant-layout-header .logo {
    margin-right: 16px;
  }
  body
    .ant-layout.front
    .container_small
    .logowrp
    .ant-menu-inline:not(.ant-menu-dark)
    > .ant-menu-item {
    margin: 0px 3px;
  }
  body .ant-layout.front .container_small .logowrp .ant-menu-inline {
    font-size: 14px;
  }
  body .ant-layout.front .container_small .logowrp .contact-btn {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  body {
    .ant-layout-footer {
      .socialicons {
        margin-bottom: 16px;
        overflow: hidden;
      }
      ul.footerlinks {
        margin-bottom: 16px;
        overflow: hidden;
      }
    }
    .ant-layout-header {
      .ant-input-affix-wrapper {
        display: none;
      }
    }
  }
  body .ant-layout-header .ipact_ind .water_usages .ant-row {
    flex-wrap: wrap;
  }
  body .ant-layout-header .ipact_ind .water_usages .l_col {
    margin-bottom: 10px;
  }
  body .ant-layout-header .ipact_ind .water_usages .r_col .branderap {
    margin-bottom: 10px;
  }
  body .ant-layout-header .logo {
    margin-right: 8px;
  }
  body .ant-layout.front .container_small .logowrp {
    // flex: 0 0 130px;
  }
  body .ant-layout.front .ant-layout-header .rightside .socialicon {
    margin-right: 10px;
  }
  body .ant-layout-footer .footerrow .copyright {
    text-align: center;
  }
  body .ant-layout-footer .footerrow .footerright {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  body .ant-layout-footer .footerrow .footerright a.subscribe {
    margin-right: 16px;
  }
  body .ant-layout-footer {
    padding: 16px 0px;
  }
  .scbscribe_modal .ant-modal-content {
    background: #edffef;
    padding: 32px 16px;
  }

  body .ant-layout-footer .footer_sec_1 .newsletter .rcol .subscribe {
    margin-top: 10px;
  }
  body .ant-layout.front .container_small .logowrp .desktopmenu {
    display: none;
  }
  body .ant-layout.front .container_small .logowrp .mobmenu {
    display: inline-block;
  }
}
@media only screen and (max-width: 500px) {
  .ant-tabs-tab-btn img {
    width: 32px;
  }
}
